























import { Component, Inject, Prop, Ref, Vue, Watch } from "vue-property-decorator";
import { Observer } from "mobx-vue";

@Observer
@Component
export default class DragonAvatar extends Vue {
  @Prop() avatar: any;
  @Prop() video!: any;
  @Prop({ default: false }) hideVideo!: boolean;
  @Prop({ default: 444 }) videoSize!: number;
  @Ref("videoPlayer") videoPlayer!: any;

  url: string | null = null;
  type = "video/mp4";
  videoError = false;

  @Watch("avatar", { immediate: true }) async onAvatarChanged() {
    await this.updateImage();
  }
  @Watch("video", { immediate: true }) async onVideoChanged() {
    this.videoError = false;
  }

  async updateImage() {
    try {
      this.url = this.avatar;
    } catch (error) {
      console.error("onAvatarChanged", error);
    }
  }

  onLoadImgError() {
    this.url = require("@/assets/summon/dragon-sb-inactive.png");
  }
}
